<template>
  <div class="admin-container" v-if="data">
    <v-row>
      <v-col cols="12" md="12">
        <v-btn tile small @click="$router.push({ name: 'Visualization' })"
          >Zpět</v-btn
        >
      </v-col>
    </v-row>
    <v-row
      class="mt-8"
      v-if="['SUPERUSER', 'PROJECT_MANAGER'].includes(user.role)"
    >
      <v-col cols="4" md="3">
        <v-text-field
          v-model="data.name"
          label="Název vizualizace"
        ></v-text-field>
      </v-col>
      <v-col align-self="center">
        <v-btn small color="secondary" tile @click="updateVisualization"
          >Uložit</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="justify-space-between">
      <v-col cols="12" md="4">
        <span>{{ data.name }}</span>
        <span class="d-block col-secondary-3"
          ><span class="d-inline-block mr-2">{{ data.count_text }}</span>
          {{ data.created_at }}</span
        >
      </v-col>
      <v-col align-self="center">
        <v-row class="justify-md-end mb-4">
          <!-- <v-col align-self="center" md="5" class="pa-0 flex-md-0 mt-4 mt-md-0"> -->
          <v-row class="justify-md-end">
            <!-- <v-col class="flex-grow-0"> -->
            <v-btn
              v-if="user.role != 'END_USER' && user.role != 'COMPANY_CUSTOMER'"
              x-small
              tile
              color="secondary"
              height="32"
              class="mobile-width-send-message visualization-fix-button-image"
              @click="fireInputEvent"
              ><v-icon small color="primary">mdi-plus</v-icon> Přidat
              obrázek</v-btn
            >
            <!-- </v-col> -->
            <!-- <v-col class="flex-grow-0"> -->
            <v-btn
              x-small
              tile
              color="secondary"
              height="32"
              class="mobile-width-send-message visualization-fix-button"
              @click="downloadAll"
              ><v-icon small color="primary">mdi-download</v-icon> Stáhnout
              vše</v-btn
            >
            <!-- </v-col> -->
          </v-row>
          <!-- </v-col> -->
        </v-row>
      </v-col>
    </v-row>
    <input
      style="display: none"
      ref="photoFile"
      type="file"
      accept="image/*"
      capture="camera"
      class="input--file"
      multiple
      @input="handleInputFile($event.target.files)"
    />
    <v-row class="photo-gallery-wrapper-detail">
      <div
        class="photo-gallery-single-item-detail"
        v-for="(image, imageIdx) in data.documents"
        :key="image.id"
      >
        <div class="photo-gallery-single-item-detail-image-wrapper">
          <img
            class="photo-gallery-single-item-detail-image cursor-pointer"
            style="object-fit: cover"
            :src="image.path"
            :alt="image.name"
            @click="downloadDoc(image.documentId)"
          />
          <input
            type="text"
            class="photo-gallery-single-item-date"
            v-model="image.name"
            :disabled="
              user.role == 'END_USER' || user.role == 'COMPANY_CUSTOMER'
            "
          />
          <div class="document-close" style="top: 12px">
            <button
              @click="downloadSingle(image)"
              style="
                top: -1px;
                position: absolute;
                right: 20px;
                text-decoration: none;
              "
            >
              <v-icon style="color: #dba822 !important; font-size: 22px"
                >mdi-download</v-icon
              >
            </button>
            <button
              v-if="user.role != 'END_USER' && user.role != 'COMPANY_CUSTOMER'"
              @click="
                (focusedId = image.documentId),
                  (focusedIdx = documentIdx),
                  (dialog = true)
              "
            >
              <img :src="require('@/assets/zavrit.svg')" alt="" />
            </button>
          </div>
        </div>
      </div>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog" :max-width="450" persistent class="rounded-0">
        <v-card>
          <v-card-title class="headline">Odstranit dokument</v-card-title>
          <v-card-subtitle
            >Opravdu si přejete odstranit dokument ?</v-card-subtitle
          >
          <v-card-text>
            <v-row> </v-row>
            <v-row class="mt-6">
              <v-col class="text-center">
                <v-btn
                  color="primary"
                  small
                  tile
                  width="100%"
                  @click="handleDeleteFile"
                  >Odstranit</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-btn small text @click="dialog = false" width="100%"
                  >Zrušit</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions> </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row class="mt-8">
      <v-col class="text-right">
        <v-btn
          small
          tile
          color="secondary"
          class="mr-4"
          @click="$router.push({ name: 'Visualization' })"
          >Zrušit</v-btn
        >
        <v-btn
          small
          tile
          color="primary"
          v-if="user.role != 'END_USER' && user.role != 'COMPANY_CUSTOMER'"
          @click="update"
          >Uložit</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import authHandler from "../api/authHandler";
import permissionDeniedHandler from "../api/permissionDeniedHandler";
import bus from "../plugins/bus";
import errorHandler from "../api/errorHandler";
import visualizationService from "../api/visualizationService";
import documentService from "../api/documentService";
import downloadService from "../api/downloadService";
import axios from "axios";

export default {
  data() {
    return {
      data: null,
      dialog: false,
      focusedIdx: null,
      focusedId: null,
    };
  },
  async created() {
    try {
      //   await this.$store.dispatch("getCurrentUser");
      if (this.getSelectedBuilding() != null) {
        await this.show();
      } else {
        this.$router.push({ name: "Dashboard" });
        bus.$emit("snackbar", {
          text: "Není vybrána žádná stavba.",
          color: "error",
        });
      }
    } catch (error) {
      if (!authHandler(error)) this.$router.push({ name: "Login" });
      permissionDeniedHandler(error, this.$router);
    }
  },
  methods: {
    async updateVisualization() {
      try {
        bus.$emit("processing");
        await visualizationService.update(this.$route.params.id, this.data);
        bus.$emit("processing", false);
      } catch (error) {
        bus.$emit("processing", false);
        errorHandler(error);
      }
    },
    async downloadDoc(id) {
      try {
        bus.$emit("processing");
        await documentService.show(id);
        bus.$emit("processing", false);
      } catch (error) {
        bus.$emit("processing", false);
        errorHandler(error);
      }
    },
    async downloadSingle(image) {
      try {
        if (image.path) {
          let filename = image.path.split("/");
          filename = filename[filename.length - 1];
          const getImage = await axios.get(image.path, {
            responseType: "blob",
          });
          const link = document.createElement("a");
          const downloadUrl = window.URL.createObjectURL(
            new Blob([getImage.data])
          );
          link.href = downloadUrl;
          link.target = "_blank";
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async downloadAll() {
      try {
        bus.$emit("processing");
        const data = await visualizationService.download(this.$route.params.id);
        await downloadService.downloadFromCode(data);
        bus.$emit("processing", false);
      } catch (error) {
        console.error(error);
        errorHandler(error);
        bus.$emit("processing", false);
      }
    },
    async handleInputFile(f) {
      try {
        bus.$emit("processing");
        for (let fidx = 0; fidx < f.length; fidx++) {
          const { data: document } = await documentService.store(f[fidx]);
          this.data.documents.push({
            documentId: document.id,
            name: document.client_name,
            mime: document.mime,
            path: document.document_path,
          });
        }
        await visualizationService.update(this.$route.params.id, this.data);
        bus.$emit("snackbar", {
          text: `Podařilo se nahrát fotky`,
          color: "info",
        });
        this.$refs.photoFile.values = [];
        await this.show();
        bus.$emit("processing", false);
      } catch (error) {
        bus.$emit("processing", false);
        errorHandler(error);
      }
    },
    fireInputEvent() {
      this.$refs.photoFile.click();
    },
    async handleDeleteFile() {
      try {
        bus.$emit("processing");
        await documentService._delete(this.focusedId);
        this.data.documents.splice(this.focusedIdx, 1);
        bus.$emit("processing", false);
        this.dialog = false;
      } catch (error) {
        bus.$emit("processing", false);
        console.error(error);
        errorHandler(error);
      }
    },
    getSelectedBuilding() {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      return building;
    },
    async update() {
      try {
        bus.$emit("processing");
        await visualizationService.update(this.$route.params.id, this.data);
        bus.$emit("processing", false);
      } catch (error) {
        bus.$emit("processing", false);
        errorHandler(error);
      }
    },
    async show() {
      try {
        bus.$emit("processing");
        const data = await visualizationService.show(this.$route.params.id);
        this.data = data;
        console.log(data);
        bus.$emit("processing", false);
      } catch (error) {
        console.error(error);
        bus.$emit("processing", false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit("snackbar", {
            text: error.response.data.error.message,
            color: "error",
          });
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    stahnout() {
      return {
        backgroundImage: `url(${require("@/assets/stahnout.svg")} !important`,
      };
    },
  },
};
</script>

<style></style>
